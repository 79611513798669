import { getQuerySizeInBytes, hashQuery } from '@/ui';
import {
  CONTENTFUL_ACCESS_TOKEN,
  CONTENTFUL_ENVIRONMENT,
  CONTENTFUL_PREVIEW_ACCESS_TOKEN,
  CONTENTFUL_SPACE_ID,
  MAX_QUERY_SIZE,
  APQ_LOG
} from '../constants';
import { compressQuery } from '../utils';

/**
 * This class handles the interaction with the Contentful GraphQL library
 */
export class ContentfulClient {
  // Access token to apply to the GraphQL call
  accessToken = CONTENTFUL_ACCESS_TOKEN;
  // The Contentful environment to execute the call against
  environmentId = CONTENTFUL_ENVIRONMENT;
  // Access token to apply if in preview mode
  previewAccessToken = CONTENTFUL_PREVIEW_ACCESS_TOKEN;
  // The Contentful space to execute the call against
  spaceId = CONTENTFUL_SPACE_ID;

  async fetchGraphQL(query: string, preview = false, retries = 2) {

    let attempt = 0;
    const tokenToPass = preview ? this.previewAccessToken : this.accessToken;

    while (attempt <= retries) {
      const shrinkQuery = compressQuery(query);
      const querySize = getQuerySizeInBytes(shrinkQuery);

      let requestBody;
  
      if (Number(querySize) > MAX_QUERY_SIZE ) {
        
        if (APQ_LOG) {
          // Console log to check if Automatic Persisted Queries are firing
          console.info(`\nQuery size ${getQuerySizeInBytes(shrinkQuery)} bytes, using Automatic Persisted Queries`);
        }
  
        // Generate the a sha256Hash value of the query for retrival from 
        // Contentful query cache
        const hash = hashQuery(shrinkQuery);

        if (APQ_LOG) {
          // Console log to check hash value
          console.log(`\nQuery hash: ${hash}`);
        }

        // Avoid rate limiting by waiting 10ms before sending the request
        await new Promise((resolve) => setTimeout(resolve, 10));        

        // Create the request GraphQL query and persistedQuery variables
        requestBody = {
          query: shrinkQuery,
          extensions: {
            persistedQuery: {
              version: 1,
              sha256Hash: hash
            }
          }
        };
  
        // Send the persisted queries request to Contentful to store the query ready for execution
        const persistResponse = await fetch(
          `https://graphql.contentful.com/content/v1/spaces/${this.spaceId}/environments/${this.environmentId}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${tokenToPass}`
            },
            body: JSON.stringify(requestBody)
          }
        );
  
        const persistJson = await persistResponse.json();
  
        // Error handlng in case the persist request fails
        if (persistJson.errors) {
          const error = persistJson.errors[0];        
          switch (error.extensions.contentful.code) {
            case 'PERSISTED_QUERY_MISMATCH':
              throw new Error(`Error: ${error.message}. More details: ${error.extensions.contentful.documentationUrl}`);
            case 'PERSISTED_QUERY_NOT_FOUND':
              throw new Error(`Error: ${error.message}. More details: ${error.extensions.contentful.documentationUrl}`);
            default:
              throw new Error(`Error persisting the query. \n ${error}`);
          }
        }
  
        // Change the requestBody to just include the persisted query information
        requestBody = {
          extensions: {
            persistedQuery: {
              sha256Hash: hash,
              version: 1
            }
          }
        };
  
      } else {      
        requestBody = { query: shrinkQuery };
      }

      try {
        // Depending on the size of the query either send the full query or the persisted query hash
        const response = await fetch(
          `https://graphql.contentful.com/content/v1/spaces/${this.spaceId}/environments/${this.environmentId}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${tokenToPass}`
            },
            body: JSON.stringify(requestBody)
          }
        );

        const json = await response.json();

        if (Object.keys(json).includes('errors')) {
          const { errors } = json;
    
          const unresolvableLinkError = errors.filter(
            (e: any) => e?.extensions?.contentful?.code === 'UNRESOLVABLE_LINK'
          );
    
          // Check if the only errors we have are about invalid references
          if (
            unresolvableLinkError &&
            unresolvableLinkError?.length > 0 &&
            unresolvableLinkError.length === errors.length
          ) {
            unresolvableLinkError.map((err: any) => {
              const {
                extensions: {
                  contentful: {
                    details: { type, linkId, linkingEntryId }
                  }
                }
              } = err;
              console.log(
                `Draft/invalid reference found: ${type} entry with ID '${linkId}' references entry ID '${linkingEntryId}'`
              );
            });
          } else {    
            throw json;
          }
        }
    
        return json;
      } catch (error: any) {

        if (error.message.includes("PERSISTED_QUERY_NOT_FOUND")) {
          console.log(`Retry attempt: ${attempt}`);
          attempt++;
          continue;
        } else {
          console.log(`error: ${error}`);
          throw error; 
        }

      }
    }

    throw new Error("Failed after multiple attempts");

  }
}
