import styles from './ComponentSolutionsHero.module.scss';

import React from 'react';

// @ts-ignore
import isDarkColor from 'is-dark-color';

import { 
  SolutionsHeroComponent, 
  ComponentCta, 
  PageDataTag
} from '@/types';

import {
  ComponentCtaButton,
  Breadcrumb,
  TagPill,
  ResponsiveImage,
  ComponentStickyMenu
} from '@/ui/';

import { colorPickerHexToClassName } from '@/ui/constants';
import { convertMarkdown } from '@/ui/utils';

const ComponentSolutionsHero = ({
  pageData,
  heroHeading,
  heroColour = 'white',
  heroMedia,
  heroBody,
  ctas,
  showBreadcrumb = false,
}: SolutionsHeroComponent) => {

  const { 
    tags, 
    anchorLinks 
  } = pageData;

  heroColour = heroColour?.toLowerCase().replace(' ', '-');

  const styleColour = colorPickerHexToClassName[heroColour]
    ? colorPickerHexToClassName[heroColour]
    : heroColour;

  const isImage = heroMedia?.contentType.startsWith('image');
  const hasCtas = !!ctas.length;

  return (
    <div
      className={`${styles.solutionsHero} ${styles[styleColour]} ${
        !isImage ? styles['no-image'] : ''
      }`}
    >
      <div className={styles.solutionsHero__inner}>
        <div
          className={`${styles.solutionsHero__content} ${
            showBreadcrumb ? styles['breadcrumb'] : ''
          }`}
        >
          {showBreadcrumb && (
            <div className={styles.solutionsHero__breadcrumb}>
              <Breadcrumb usedOnLightBackground={!isDarkColor(heroColour)} />
            </div>
          )}

          <div className={styles.solutionsHero__solutionInfo}>
            {tags.length > 0 && (
              <div className={styles.solutionsHero__solutionTags}>
                {tags.map((tag: PageDataTag) => {
                  return (
                    <div key={tag.tagName}>
                      <TagPill text={tag.tagName} />
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <h1 className={styles.solutionsHero__title}>{heroHeading}</h1>

          {heroBody && (
            <div className={styles.solutionsHero__description}>
              {convertMarkdown(heroBody)}
            </div>
          )}

          {hasCtas && (
            <div className={styles.solutionsHero__clickthroughs}>
              {ctas.map((cta: ComponentCta) => {
                return (
                  <ComponentCtaButton
                    key={cta.ctaText}
                    ctaType='link'
                    ctaText={cta.ctaText}
                    ctaAria={cta.ctaAria}
                    ctaStyle={cta.ctaStyle}
                    ctaIcon={cta.ctaIcon}
                    ctaIconPosition={cta.ctaIconPosition}
                    ctaUrl={cta.ctaUrl}
                    ctaPage={cta.ctaPage}
                    cssClass={styleColour}
                    ctaMarketoFormId={cta.ctaMarketoFormId}
                    ctaDigitalAsset={cta.ctaDigitalAsset}
                  />
                );
              })}
            </div>
          )}
        </div>
        <div className={styles.solutionsHero__image}>
          {isImage && (
            <>
              <div className={styles.solutionsHero__imageWrapper}>
                <ResponsiveImage
                  sizes={{ sm: 400, md: 768 }}
                  className={styles.heroImageClip__file}
                  media={heroMedia}
                  priority
                />
              </div>
              {heroMedia?.description && (
                <div className={styles.solutionsHero__caption}>
                  <span>{heroMedia?.description}</span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {anchorLinks.length > 0 && (
        <ComponentStickyMenu anchorLinks={anchorLinks} ctas={ctas} styleColour={styleColour} />
      )}  
    </div>
  );
};

export default ComponentSolutionsHero;
