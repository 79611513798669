import { useState, useEffect } from "react";
import { trackGA } from "../helpers";

interface MarketoProps {
    baseUrl?: string;
    munchkinId: string;
    formId: string;
    callback?: (form: any) => void;
    prePopulatedFields?: { [key: string]: string };
}

const appendScript = ({ baseUrl, setScriptLoaded }: { baseUrl: string, setScriptLoaded: Function }) => {
    if ((window as any).MktoForms2) {
        return setScriptLoaded(true);
    } else {
        const script = document.createElement("script");
        script.src = `${baseUrl}/js/forms2/js/forms2.min.js`;
        script.onload = () => ((window as any).MktoForms2 ? setScriptLoaded(true) : null);
        document.body.appendChild(script);
    }
}

export const useMarketo = ({ baseUrl = '//page.oneocean.com', munchkinId, formId, prePopulatedFields }: MarketoProps) => {
    const [scriptLoaded, setScriptLoaded] = useState(false);

    useEffect(() => {
        if (scriptLoaded) {
            if (!(window as any).MktoForms2) return;
            (window as any).MktoForms2.loadForm(baseUrl, munchkinId, formId, (form: any) => {
                document.getElementById('marketoLoader')!.style.display = 'none';
                const formElement = form.getFormElem();

                // Pre populate form fields
                if (prePopulatedFields) {
                    form.vals({ ...prePopulatedFields });
                }

                const utmField = document.querySelector('input[name="Driver_ID_Most_Recent__c"]') as HTMLInputElement;
                if (utmField) {
                    Object.keys(window.sessionStorage).forEach(key => {
                        if (key.includes('sfcid')) {
                            const sfcidData = window.sessionStorage.getItem(key);
                            if (sfcidData) {
                                utmField.value = sfcidData;
                                return;
                            }
                        }
                    });
                }

                // You cant submit over localhost so you can use onSubmit to test.
                // form.onSubmit(() => {
                //     formElement.hide();
                //     document.getElementById(`success-${formId}`)!.style.display = 'block';
                //     trackGA('marketo_submission', { title: formId });
                //     Object.keys(window.sessionStorage).forEach(key => {
                //       if (key.includes('sfcid') || key.includes('utm')) {
                //         window.sessionStorage.removeItem(key);
                //       }
                //     });
                //     return false;
                // });

                form.onSuccess(() => {
                    formElement.hide();
                    document.getElementById(`success-${formId}`)!.style.display = 'block';
                    trackGA('marketo_submission', { title: formId });
                    Object.keys(window.sessionStorage).forEach(key => {
                        if (key.includes('sfcid') || key.includes('utm')) {
                            window.sessionStorage.removeItem(key);
                        }
                    });
                    return false;
                });
            });

            // Removes style attribute
            (window as any).MktoForms2.whenRendered((form: any) => {
                const formElement = form.getFormElem();

                formElement[0].removeAttribute('style');
            });

        } else {
            appendScript({ baseUrl, setScriptLoaded });
        }

    }, [baseUrl, formId, munchkinId, prePopulatedFields, scriptLoaded]);
}