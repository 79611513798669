import styles from './ComponentFiftyFiftyCallout.module.scss';

import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player/lazy';

import { ComponentCtaButton, Icon, ResponsiveImage } from '@/ui';
import { convertMarkdown, useIsMobile } from '@/ui/utils';

import { FiftyFiftyComponent } from '@/types';
import { colorPickerHexToClassName } from '@/ui/constants';

const ComponentFiftyFiftyCallout = ({
  calloutHeading,
  calloutBody,
  calloutImage,
  calloutStyle,
  calloutCaption,
  pillarColour,
  calloutCta,
}: FiftyFiftyComponent) => {
  const [isVideo, setIsVideo] = useState(false);

  const isMobile = useIsMobile();
  const hasMedia = calloutImage?.url;

  const isImage = calloutImage?.contentType.startsWith('image');
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const video = !isImage;
    if (video) {
      setIsVideo(video);
    }
  }, [calloutImage, isImage]);

  const imageSide = calloutStyle.toLowerCase().includes('right')
    ? 'right'
    : 'left';
  const styleType = calloutStyle.toLowerCase().includes('branded')
    ? 'branded'
    : 'standard';

  const styleColour = pillarColour
    ? colorPickerHexToClassName[pillarColour]
    : '';

  return (
    <div
      className={`${styles.fiftyFiftyCallout} ${styles[styleType]} ${styles[imageSide]}`}
    >
      <div className={styles.fiftyFiftyCallout__imageColumn}>
        <div
          className={`${styles.fiftyFiftyCallout__imageWrapper} ${
            isPlaying ? styles.playing : ''
          }
          ${isVideo ? styles.video : styles.image}
          `}
        >
          {isVideo && (
            <button
              type='button'
              className={`${styles.fiftyFiftyCallout__playButton} ${
                isPlaying ? styles.pause : styles.play
              }`}
              onClick={togglePlayPause}
            >
              {isPlaying ? (
                <Icon
                  className={styles.fiftyFiftyCallout__playIcon}
                  id='pause'
                  width={30}
                  height={30}
                />
              ) : (
                <Icon
                  className={styles.fiftyFiftyCallout__playIcon}
                  id='play'
                  width={40}
                  height={40}
                />
              )}
            </button>
          )}

          {isImage && hasMedia && (
            <ResponsiveImage
              media={calloutImage}
              sizes={{ sm: 400, md: 700 }}
              className={styles.fiftyFiftyCallout__image}
            />
          )}

          {isVideo && hasMedia && (
            <ReactPlayer
              className={`${styles.fiftyFiftyCallout__video}`}
              url={calloutImage.url}
              width='100%'
              height='100%'
              playing={isPlaying}
              muted
              loop
              playsinline
              controls={false}
            />
          )}
        </div>
        {calloutCaption && (
          <div
            className={`${styles.fiftyFiftyCallout__caption} ${styles[styleColour]}`}
          >
            <span>{calloutCaption}</span>
          </div>
        )}
      </div>

      <div
        className={`${styles.fiftyFiftyCallout__content} ${
          calloutCaption ? styles.fiftyFiftyCallout__spacing : ''
        }`}
      >
        <div>
          <h2 className={styles.fiftyFiftyCallout__heading}>
            {calloutHeading}
          </h2>
          <div className={styles.fiftyFiftyCallout__text}>
            {convertMarkdown(calloutBody)}
          </div>
          {calloutCta && (
            <div className={styles.fiftyFiftyCallout__cta}>
              <ComponentCtaButton {...calloutCta} ctaType='link' />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ComponentFiftyFiftyCallout;
