import { DataFeaturedLink } from '@/types';
import RelatedInsight from './RelatedInsight';

type RelatedInsightFromFeaturedLinkProps = {
  featuredLink: DataFeaturedLink;
};

const RelatedInsightFromFeaturedLink = ({
  featuredLink,
}: RelatedInsightFromFeaturedLinkProps) => {
  return (
    <RelatedInsight
      key={featuredLink.featuredPage.slug}
      openInNewTab={!!featuredLink.featuredUrl}
      slug={featuredLink.featuredUrl ?? featuredLink.featuredPage.slug}
      heading={featuredLink.featuredDescription}
      media={featuredLink.featuredImage}
      showButton={featuredLink.featuredShowButton}
      buttonText={featuredLink.featuredButtonText}
    />
  );
};

export default RelatedInsightFromFeaturedLink;
