import '@/styles/globals.scss';

import App, { AppContext, AppProps } from 'next/app';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { GoogleTagManager } from '@next/third-parties/google'

import { ContentfulLivePreview } from '@contentful/live-preview';

import { useEffect } from 'react';
import algoliasearch from 'algoliasearch';

import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react';

import { UIContextProviderProps } from '@/types';
import { CookiePro, UIContext, sourceSansPro } from '@/ui';
import { Layout } from '@/layout/Layout';
import { MobileProvider } from '@/ui/utils/MobileProvider';

const { publicRuntimeConfig } = getConfig();

function isSafari() {
  if (typeof window !== 'undefined') {
    return (
      /Safari/.test(window.navigator.userAgent) &&
      !/Chrome/.test(window.navigator.userAgent)
    );
  }
  return false;
}

const {
  NEXT_PUBLIC_ALGOLIA_INDEX,
  NEXT_PUBLIC_ALGOLIA_APP_ID,
  NEXT_PUBLIC_ALGOLIA_ADMIN_KEY,
} = publicRuntimeConfig;

const algoliaClient = algoliasearch(
  NEXT_PUBLIC_ALGOLIA_APP_ID ?? '',
  NEXT_PUBLIC_ALGOLIA_ADMIN_KEY ?? ''
);

function OneOceanApp({ Component, pageProps }: AppProps) {
  const { baseData, paths, locale, isPreviewMode } = pageProps;
  const contextData: UIContextProviderProps = {
    ...baseData,
    paths,
    locale,
    algoliaClient,
    indexName: NEXT_PUBLIC_ALGOLIA_INDEX,
  };
  const router = useRouter();
  const { gtmId, gtmEnvironment, gtmAuth } = contextData;

  useEffect(() => {
    if (isSafari()) {
      document.body.classList.add('safari');
    }
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.forEach((value, key) => {
      window.sessionStorage.setItem(key, value);
    });
  }, [router.asPath]);

  const previewLocale = locale || 'en';
  
  ContentfulLivePreview.init({ locale: previewLocale });

  function ComponentWrapper() {
    if (isPreviewMode) {
      return (
        <ContentfulLivePreviewProvider locale={previewLocale}>
          <Component {...pageProps} />
        </ContentfulLivePreviewProvider>
      );
    } else {
      return <Component {...pageProps} />;
    }
  }
  
  return (
    <>
      <UIContext.Provider value={contextData}>
        <MobileProvider>
          <Layout wrapperClassName={`main ${sourceSansPro.className}`} preview={isPreviewMode}>
            
            <ComponentWrapper />

          </Layout>
        </MobileProvider>

        {gtmId && gtmEnvironment && gtmAuth && (
          <>
            <GoogleTagManager gtmId={`${gtmId}`} auth={`${gtmAuth}`} preview={`${gtmEnvironment}`} />
          </>
        )}

        { !isPreviewMode && (
          <CookiePro />
        )}        
      </UIContext.Provider>
    </>
  );
}

OneOceanApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  const { locale, asPath } = appContext?.router;

  return {
    ...appProps,
    pageProps: { ...appProps.pageProps, locale, asPath },
  };
};

export default OneOceanApp;
