import styles from './ComponentCalloutHero.module.scss';

import { ComponentCtaButton, convertMarkdown } from '@/ui';
import type { CalloutHero, ComponentCta } from '@/types';
import HeroImageClip from './HeroImageClip';

const ComponentCalloutHero = ({
  heroHeading,
  heroMedia,
  heroBody,
  ctas,
  heroColour = '#04aa9e',
  heroSpacing = false,
  isCarouselSlide = false,
}: CalloutHero) => {
  const hasMedia = !!heroMedia?.url;

  return (
    // @ts-expect-error: --callout does not exist on style interface.
    <div style={{ '--callout': `${heroColour}` }}
      className={`
      ${styles.calloutHero} 
      ${!hasMedia && styles.withoutMedia} 
      ${(heroSpacing && !isCarouselSlide) && styles.withSpacing}
      ${isCarouselSlide && styles.isCarousel}
    `}
    >
      <div
        className={`
          ${styles.calloutHero__container} 
          ${hasMedia && styles.noPaddingRight}
          ${!hasMedia && isCarouselSlide && styles.noPadding}
        `}
      >
        <div className={styles.calloutHero__content}>
          <h2 className={styles.calloutHero__heading}>{heroHeading}</h2>
          {heroBody && (
            <div className={styles.calloutHero__body}>
              {convertMarkdown(heroBody)}
            </div>
          )}
          {!!ctas.length && (
            <div className={styles.calloutHero__ctas}>
              {ctas.map((cta: ComponentCta) => (
                <ComponentCtaButton
                  key={cta.ctaText}
                  ctaType='link'
                  ctaText={cta.ctaText}
                  ctaAria={cta.ctaAria}
                  ctaStyle={cta.ctaStyle}
                  ctaIcon={cta.ctaIcon}
                  ctaIconPosition={cta.ctaIconPosition}
                  ctaUrl={cta.ctaUrl}
                  ctaPage={cta.ctaPage}
                  ctaMarketoFormId={cta.ctaMarketoFormId}
                  ctaDigitalAsset={cta.ctaDigitalAsset}
                />
              ))}
            </div>
          )}
        </div>
        {hasMedia && (
          <div className={styles.calloutHero__image}>
            <HeroImageClip
              heroMedia={heroMedia}              
              ovalBottom
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ComponentCalloutHero;
