import React from 'react';
// @ts-ignore
import isDarkColor from 'is-dark-color';

import { convertMarkdown, useIsMobile } from '@/ui/utils';
import { StandardHeroComponent, ComponentCta } from '@/types';
import { ComponentCtaButton, HeroImageClip, Breadcrumb } from '@/ui/';

import { colorPickerHexToClassName } from '@/ui/constants';

import styles from './ComponentStandardHero.module.scss';
import { useRouter } from 'next/router';

const ComponentStandardHero = ({
  heroHeading,
  heroColour,
  heroSubheading,
  heroMedia,
  heroBody,
  ctas,
  showBreadcrumb = false,
}: StandardHeroComponent) => {
  heroColour = heroColour?.toLowerCase().replace(' ', '-');

  const { locale = 'en' } = useRouter();

  const styleColour = colorPickerHexToClassName[heroColour]
    ? colorPickerHexToClassName[heroColour]
    : heroColour;

  const isMobile = useIsMobile();

  const isImage = heroMedia?.contentType.startsWith('image');
  const hasCtas = !!ctas?.length;

  let captionText;
  if (locale !== 'en') {
    captionText = isImage ? 'Image:' : 'Vidéo:';
  } else {
    captionText = isImage ? 'Image:' : 'Video:';
  }

  return (
    <div
      className={`${styles.standardHero} ${styles[styleColour]} ${
        !isImage ? styles['no-image'] : ''
      }`}
    >
      <div className={styles.standardHero__inner}>
        <div
          className={`${styles.standardHero__content} ${
            showBreadcrumb ? styles['breadcrumb'] : ''
          }`}
        >
          {showBreadcrumb && (
            <div className={styles.standardHero__breadcrumb}>
              <Breadcrumb usedOnLightBackground={!isDarkColor(heroColour)} />
            </div>
          )}
          <span className={styles.standardHero__subtitle}>
            {heroSubheading}
          </span>

          <h1 className={styles.standardHero__title}>{heroHeading}</h1>

          {heroBody && (
            <div className={styles.standardHero__description}>
              {convertMarkdown(heroBody)}
            </div>
          )}

          {hasCtas && (
            <div className={styles.standardHero__clickthroughs}>
              {ctas.map((cta: ComponentCta) => {
                return (
                  <ComponentCtaButton
                    key={cta.ctaText}
                    ctaType='link'
                    ctaText={cta.ctaText}
                    ctaAria={cta.ctaAria}
                    ctaStyle={cta.ctaStyle}
                    ctaIcon={cta.ctaIcon}
                    ctaIconPosition={cta.ctaIconPosition}
                    ctaUrl={cta.ctaUrl}
                    ctaPage={cta.ctaPage}
                    cssClass={styleColour}
                    ctaMarketoFormId={cta.ctaMarketoFormId}
                    ctaDigitalAsset={cta.ctaDigitalAsset}
                  />
                );
              })}
            </div>
          )}

          {isImage && !isMobile && heroMedia?.description && (
            <div className={styles.standardHero__caption}>
              <span>
                {captionText} {heroMedia?.description}
              </span>
            </div>
          )}
        </div>
        <div className={styles.standardHero__image}>
          <div className={styles.standardHero__imageWrapper}>
            {isImage && <HeroImageClip heroMedia={heroMedia} priority />}
          </div>
        </div>
        {isMobile && heroMedia?.description && (
          <div className={styles.standardHero__captionWrapper}>
            <div className={styles.standardHero__caption}>
              <span>
                {captionText} {heroMedia.description}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ComponentStandardHero;
